import React, { useEffect, useState, useContext } from "react";
import { CartContext } from "../../context/CartContext";

import io from "socket.io-client";
import { useForm } from "react-hook-form";
import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  SimpleGrid,
  VStack,
  RadioGroup,
  Radio,
  Stack,
  Textarea,
  Select,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha";
import { offline } from "../../data/offline";
import moment from "moment";
import DiscountModal from "../order-page/Discount/DiscountModal";
import { holidayFlag } from "../../data/holidays";

moment().format();

//const socket = io.connect(process.env.REACT_APP_PRODUCTION);
const socket = io.connect(process.env.REACT_APP_DEV);

function Form(props) {
  const [value, setRadioValue] = useState("1");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const { cart, setCart, discount, discountItems, subTotal } =
    useContext(CartContext);
  const [captcha, setCaptcha] = useState(false);

  const {
    isOpen: isDiscountModalOpen,
    onOpen: onDiscountModalOpen,
    onClose: onDiscountModalClose,
  } = useDisclosure();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      time: "ASAP",
    },
  });

  useEffect(() => {
    socket.on("customer_confirm", data => {
      props.getConfirm(data);
    });
  }, []);

  const noteHandler = e => {
    setAdditionalNotes(e.target.value);
  };

  const captchaHandler = value => {
    if (value !== null) {
      setCaptcha(true);
    } else {
      setCaptcha(false);
    }
  };

  useEffect(() => {
    if (value === "1") {
      //reset to ASAP when we click the ASAP radio button
      setValue("time", "ASAP");
    }
  }, [value, setValue]);

  //cannot schedule pick up during these closed hours
  const closedTimeSlots = ["02:15 PM", "02:30 PM", "02:45 PM", "03:00 PM"];

  useEffect(() => {
    const tempSlots = [];
    //https://stackoverflow.com/questions/42832019/time-range-using-javascript-or-momentjs
    const endTime = 21; //9:30pm

    const currentTime = moment().add(0, "minutes");
    const remainder = 15 - (currentTime.minute() % 15);
    let offset = 0;
    //if first slot is xx:15    endtime is xx:00       +1
    //if first slot is xx:30     endtime is xx:15     0
    //if first slot is xx:45     endtime is xx:30    -1
    //if first slot is xx:00    endtime is xx:45      +2

    const lowerTimeBound = moment(currentTime).add(remainder, "minutes");

    const lowerTimeBoundMinute = lowerTimeBound.minute();
    if (lowerTimeBoundMinute === 15) {
      offset = 1;
    } else if (lowerTimeBoundMinute === 30) {
      offset = 0;
    } else if (lowerTimeBoundMinute === 45) {
      offset = -1;
    } else if (lowerTimeBoundMinute === 0) {
      offset = 2;
    }

    const lowerTimeBoundHour = lowerTimeBound.hour();

    for (let i = 0; i < (endTime - lowerTimeBoundHour) * 4 + offset; i++) {
      const timeInterval = moment(lowerTimeBound)
        .add(15 * i, "minutes")
        .format("hh:mm A");
      tempSlots.push(timeInterval);
    }

    let pickupTimes = tempSlots.filter(time => !closedTimeSlots.includes(time));

    setTimeSlots(pickupTimes);
  }, []);

  return (
    <Flex
      mt="2rem"
      fontSize="24px"
      fontWeight={700}
      direction="column"
      width={{ base: "100%", lg: "80%" }}
      fontFamily="inner"
    >
      <Text mb="1rem">Customer Information</Text>
      <form
        onSubmit={handleSubmit(data => {
          let timeFlag = offline();
          if (timeFlag && cart.length !== 0) {
            if (
              discount.item === "none" &&
              discount.percentage === 0 &&
              parseFloat(subTotal) > 35.0
            ) {
              //set error to tell customer to pick an option
              //console.log(typeof subTotal);
              onDiscountModalOpen();
            } else {
              data.additionalNotes = additionalNotes;
              data.cart = cart;
              data.discountInfo = discount;
              data.subTotal = subTotal;
              props.getLoading(true);
              socket.emit("customer_order", { data: data });
            }
          }
        })}
      >
        <DiscountModal
          isOpen={isDiscountModalOpen}
          onClose={onDiscountModalClose}
        ></DiscountModal>
        <FormControl>
          <FormLabel htmlFor="name">Name</FormLabel>
          <Input
            id="name"
            type="text"
            focusBorderColor="black"
            borderColor="#c4c4c4"
            maxLength={64}
            {...register("name", { required: "*Please enter your name" })}
          />
          <Text fontSize="16px" color="red.500" mt="0.5rem">
            {errors.name?.message}
          </Text>
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: 0, lg: 10 }}
          >
            <VStack align="flex-start">
              <FormLabel htmlFor="email" mt="1rem">
                Email
              </FormLabel>
              <Input
                id="email"
                type="email"
                focusBorderColor="black"
                borderColor="#c4c4c4"
                maxLength={256}
                {...register("email", {
                  required: "*Please enter your email",
                })}
              />
              <FormHelperText>Ex: john.doe@gmail.com</FormHelperText>
              <Text fontSize="16px" color="red.500">
                {errors.email?.message}
              </Text>
            </VStack>
            <VStack align="flex-start">
              <FormLabel htmlFor="phone-number" mt="1rem">
                Phone Number
              </FormLabel>

              <Input
                id="phone"
                type="text"
                focusBorderColor="black"
                borderColor="#c4c4c4"
                width="100%"
                maxLength={15}
                {...register("phone", {
                  required: "*Please enter your telephone number",
                })}
              ></Input>

              <FormHelperText>Ex: 403-123-4567</FormHelperText>
              <Text fontSize="16px" color="red.500">
                {errors.phone?.message}
              </Text>
            </VStack>
          </SimpleGrid>
          <RadioGroup onChange={setRadioValue} value={value} mt="2rem">
            <Stack direction="row">
              <Radio value="1" defaultChecked colorScheme="red">
                <Text fontWeight={400}>As soon as possible</Text>
              </Radio>
              {holidayFlag ? null : (
                <Radio value="2" colorScheme="red">
                  <Text fontWeight={400}>Schedule for later</Text>
                </Radio>
              )}
            </Stack>
          </RadioGroup>

          {value === "2" ? (
            <>
              <FormLabel htmlFor="time-slots" mt="2rem">
                Select Pickup Time
              </FormLabel>
              <Select
                id="time"
                placeholder="Select option"
                borderColor="#c4c4c4"
                {...register("time", {
                  required: "*Please select a timeslot",
                })}
              >
                {timeSlots.map((timeObj, i) => {
                  return (
                    <option key={"time-slot:" + i} value={timeObj}>
                      {timeObj}
                    </option>
                  );
                })}
              </Select>
              <Text fontSize="16px" color="red.500">
                {errors.time?.message}
              </Text>
            </>
          ) : null}
          <FormLabel htmlFor="additional-notes" mt="2rem">
            Additional Instructions
          </FormLabel>
          <Textarea
            type="text"
            id="special-instructions"
            resize="none"
            size="lg"
            maxLength="300"
            focusBorderColor="black"
            borderColor="#c4c4c4"
            onChange={e => noteHandler(e)}
            mb="1rem"
          />
        </FormControl>

        <ReCAPTCHA
          size="normal"
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={captchaHandler}
        />
        <Button
          width="100%"
          colorScheme="green"
          mt="1rem"
          mb="2rem"
          type="submit"
          isDisabled={captcha && cart.length !== 0 ? false : true}
        >
          Submit
        </Button>
      </form>
    </Flex>
  );
}

export default Form;
